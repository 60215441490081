import { render } from "react-dom";
import { ProviderScann } from "./hooks/useScann";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const rootElement = document.getElementById("root");
render(
    <ProviderScann>
        <App />
    </ProviderScann>,
    rootElement
);

serviceWorkerRegistration.register();
reportWebVitals();
