import React, { useMemo, useState } from "react";
import { getItemProduct } from "../service";
import { IItemScanned } from "../types";

type Status = "home" | "scann" | "description" | "error";
export interface IUseScann {
	Item: IItemScanned;
	Status: Status;
	handleScan: (code: string) => Promise<void>;
	handleOpenCamera: () => void;
}

const ContextScann = React.createContext<IUseScann | null>(null);

export const ProviderScann: React.FC = ({ children }) => {
	const [Item, setItem] = useState<IItemScanned | null>(null);
	const [Status, setStatus] = useState<Status>("home");

	const handleScan = async (code: string): Promise<void> => {
		try {
			const data = await getItemProduct(code);
			setItem({
				name: data.Description,
				pricePerWeight: data.UnitPrice.toFixed(2),
				weight: data.Quantity.toFixed(2),
				total: data.Price1.toFixed(2).toString(),
			});
			setStatus("description");
		} catch (error) {
			setStatus("error");
		} finally {
			setTimeout(() => {
				setStatus("home");
				setItem(null);
			}, 4000);
		}
	};

	const handleOpenCamera = () => {
		setStatus("scann");
		setTimeout(() => {
			if (!Item) {
				setStatus("home");
			}
		}, 20000);
	};

	const value = useMemo(() => {
		return {
			Item,
			Status,
			handleOpenCamera,
			handleScan,
		} as IUseScann;
	}, [Item, Status]);

	return <ContextScann.Provider value={value}>{children}</ContextScann.Provider>;
};

export const useScann = () => {
	const context = React.useContext(ContextScann);
	if (!context) {
		throw new Error("No esta dentro de Scann Provider");
	}
	return context;
};
