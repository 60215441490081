import React from "react";

const ErrorDescription: React.FC = () => {
    return (
        <div className="flex flex-col gap-10 text-center my-10">
            <div className="flex flex-col justify-center gap-10">
                <div>
                    <p className="text-5xl font-semibold">Código de barra no valido</p>
                </div>
                <div>
                    <p className="text-4xl font-semibold">Por favor, vuelve a intentar</p>
                </div>
            </div>
        </div>
    );
};

export default ErrorDescription;
