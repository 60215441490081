import React, { useEffect, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

import boopSfx from "../assets/audio.mp3";
import { initialBarcodeValid } from "../const";
import { useScann } from "../hooks/useScann";
interface IScannProps {
	facingMode: "user" | "environment";
}

const Scanner: React.FC<IScannProps> = ({ facingMode }) => {
	const { handleScan } = useScann();
	const audio = new Audio(boopSfx);
	const [show, setshow] = useState<boolean>(false);

	useEffect(() => {
		setTimeout(() => {
			setshow(true);
		}, 1500);
	}, []);

	const barcodeScannerComponentHandleUpdate = async (error: any, result: any) => {
		if (result) {
			const substr = result.toString().substring(0, 2);
			if (initialBarcodeValid.includes(substr.toString())) {
				await audio.play();
				handleScan(result);
			}
		}
	};

	return (
		<div className="relative">
			{show && (
				<div className="absolute z-10 top-0 right-0 left-0 bottom-0 p-12">
					<div className="relative h-full p-16">
						<div className="absolute m-8 border-t-8 border-l-8 left-0 top-0 border-orangeMeatme w-14 h-14" />
						<div className="absolute m-8 border-t-8 border-r-8 right-0 top-0 border-orangeMeatme w-14 h-14" />
						<div className="absolute m-8 border-l-8 border-b-8 left-0 bottom-0 border-orangeMeatme w-14 h-14" />
						<div className="absolute m-8 border-r-8 border-b-8 right-0 bottom-0 border-orangeMeatme w-14 h-14" />
					</div>
				</div>
			)}
			<div className="flex p-10">
				<BarcodeScannerComponent
					delay={500}
					videoConstraints={{
						echoCancellation: true,
						frameRate: 120,
						facingMode: "environment",
					}}
					facingMode={facingMode}
					onUpdate={barcodeScannerComponentHandleUpdate}
				/>
			</div>
		</div>
	);
};

export default Scanner;
