import { useLocation } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import logo from "./assets/logo.png";
import background from "./assets/bc.jpg";
import Scanner from "./components/Scanner";
import Description from "./components/Description";
import { useScann } from "./hooks/useScann";
import ErrorDescription from "./components/ErrorDescription";

const MainPage = () => {
	const { search } = useLocation();
	const isFrontCamera = new URLSearchParams(search).get("isFrontCamera");
	const { Status, handleOpenCamera } = useScann();

	const item = {
		home: <img src={background} alt="codigo de barra" />,
		scann: <Scanner facingMode={isFrontCamera ? "user" : "environment"} />,
		description: <Description />,
		error: <ErrorDescription />,
	};
	return (
		<div className="flex flex-col h-screen ">
			<Header />
			<div className="grow h-full flex flex-col gap-5 my-5 justify-center items-center">
				<img src={logo} style={{ maxWidth: 200 }} alt="logo meatme" />
				<div className="flex justify-center">{item[Status]}</div>
				{Status === "home" && (
					<button
						className="bg-orangeMeatme text-xl py-3 px-6 rounded-md text-white hover:bg-orangeMeatmeDark shadow-md"
						onClick={handleOpenCamera}
					>
						Escaner
					</button>
				)}
			</div>
			<Footer />
		</div>
	);
};
export default MainPage;
