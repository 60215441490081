import React from "react";
import { useScann } from "../hooks/useScann";

const Description: React.FC = () => {
    const {
        Item: { name, pricePerWeight, total, weight },
    } = useScann();

    return (
        <div className="flex flex-col gap-10 text-center my-10">
            <p className="text-4xl font-semibold w-2/3 self-center">{name}</p>
            <div className="flex flex-col gap-5">
                <p className="text-5xl text-orangeMeatme font-semibold">Precio</p>
                <p className="text-5xl text-black font-semibold">${total}</p>
            </div>
            <div className="flex justify-center gap-10">
                <div>
                    <p className="text-2xl font-semibold">PESO</p>
                    <p className="text-2xl font-semibold">{`${weight} KG`}</p>
                </div>
                <div>
                    <p className="text-2xl font-semibold">Precio p/KILO</p>
                    <p className="text-2xl font-semibold">${pricePerWeight}</p>
                </div>
            </div>
        </div>
    );
};

export default Description;
