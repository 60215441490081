import footerMobileImage from "../assets/footer-mobile.png";
import footerTabletImage from "../assets/footer-tablet.png";

const Footer = () => {
    return (
        <>
            <img src={footerMobileImage} className="h-64 sx:hidden flex-none" alt="footer" />
            <img src={footerTabletImage} className="h-64 hidden sx:flex flex-none" alt="footer" />
        </>
    );
};

export default Footer;
