import axios from "axios";
import { IItemRequest } from "./types";

export const getItemProduct = async (code?: string): Promise<IItemRequest> => {
    const config = {
        method: "get",
        url: `https://interface02.rapidpos.com/COMBO/QA/GetItemPrice/${code}.json?CompanyName=Combo`,
        headers: {
            APIKey: "2bdba7e8-9b3b-49f9-8971-421e533d05ff",
        },
        auth: {
            password: "comboapi2",
            username: "COMBOAPI",
        },
    };
    const { data } = await axios(config);
    return data;
};
